<template>
  <main class="page-wrap">
    <p class="hits">未找到相应资源~</p>
    <router-link class="btn" to="/">回到首页</router-link>
  </main>
</template>

<script>
export default {
  name: "Page404"
};
</script>

<style scoped lang="less">
.page-wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(~@/assets/img/bg_404.png) no-repeat center;
  background-size: 101%;
  overflow: hidden;

  .hits {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 180px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    font-size: 30px;
  }

  .btn {
    position: absolute;
    bottom: 68px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 282px;
    height: 76px;
    line-height: 76px;
    text-align: center;
    font-size: 30px;
    color: #fff;
    border-radius: 15px;
    border: 1px solid #0d8dd4;
  }
}
</style>